const type = {
  ALL: "ALL",
  COMPETITION: "COMPETITION",
  INTERIOR: "INTERIOR",
  EXTERIOR: "EXTERIOR",
  ANIMATION: "ANIMATION",
  PRODUCT_MODELING: "PRODUCT_MODELING",
  PANORAMA: "PANORAMA",
  APP: "APP",
};

export default type;
